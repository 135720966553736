import { useEffect } from "react";
import { navigate } from "gatsby";

const NotFoundRedirect = () => {

  useEffect(() => {
    navigate("/");
  }, []);

  return null;
};

export default NotFoundRedirect;
